<script setup>
import NavbarComponent from "@/components/Navbar/Navbar.vue";
import BannerInicialComponent from "@/components/Banners/BannerInicial.vue";
import FooterComponent from "@/components/Footers/FooterInicial.vue";

const orange = "orange";
</script>

<template>
    <div class="flex flex-col min-h-screen">
        <header>
            <navbar-component />
            <banner-inicial-component
                :logoHooy="orange"
                :borderColor="orange"
            />
        </header>
        <main class="flex-grow">
            <router-view />
        </main>
        <footer class="mt-auto">
            <footer-component :iconVoltar="orange" :miniLogo="orange" />
        </footer>
    </div>
</template>
