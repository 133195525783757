import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const api = axios.create({
    baseURL: API_URL,
});

const getAuthConfig = () => {
    const token = localStorage.getItem("token");
    return { headers: { Authorization: token } };
};

export default {
    async apagarDadosUsuario(idUser) {
        if (!idUser) {
            throw new Error('Parâmetros obrigatórios não informados');
        }

        try {            
            await api.delete(`/admin/apagar-dados-uso-usuario/${idUser}`, getAuthConfig())
                .then(response => {
                    console.log('DATA POST: ', response)
                return 'ok';
                    
                });

            return 'error';
        }
        catch (error) {
            console.error("Erro ao apagar dados:", error);
            return 'error';
        }
    }
};
