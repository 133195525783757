import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const api = axios.create({
    baseURL: API_URL,
});

const getAuthConfig = () => {
    const token = localStorage.getItem("token");
    return { headers: { Authorization: token } };
};

export default {
    async findByUserId(userId) {
        if (!userId) {
            throw new Error('Parâmetros obrigatórios não informados');
        }

        try {
            const response = await api.get(`/user/${userId}`, getAuthConfig());
            return response.data;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    },

    async obterUsuarioLogado() {
        try {
            const response = await api.get('/user/perfil', getAuthConfig());
            return response.data;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    },

    async save(user) {
        if (!user) {
            throw new Error('Parâmetros obrigatórios não informados');
        }

        try {
            const response = await api.patch('/user/editar-perfil', user, getAuthConfig());
            return response.data;
        }
        catch (error) {
            console.error('Erro ao salvar dados:', error);
            throw error;
        }
    },

    async obterHistoricoDetalhesAcao(id) {
        if (!id) {
            throw new Error('Parâmetros obrigatórios não informados');
        }

        try {
            const response = await api.get(`/user/historico/${id}`, getAuthConfig());
            return response.status === 200 ? response.data : null;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    },

    async obterHistoricoDeReflexoes() {
        try {
            const response = await api.get('/user/perfil', getAuthConfig());

            if (response.status === 200 && response.data.user.asktoGo) {
                return response.data.user.asktoGo.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            }

            return null;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    },

    async obterHistoricoAcoesBemEstar() {
        return this._obterHistoricoFiltrado('questions', item => item.difficulty !== "custom");
    },

    async obterHistoricoExperienciasPessoais() {
        return this._obterHistoricoFiltrado('questions', item => item.difficulty === "custom");
    },

    async _obterHistoricoFiltrado(key, filterFn) {
        try {
            const response = await api.get('/user/perfil', getAuthConfig());

            if (response.status === 200 && response.data.user[key]) {
                return response.data.user[key].filter(filterFn);
            }

            return null;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    },

    async cadastrarNovoUsuario(user) {
        if (!user) {
            throw new Error('Parâmetros obrigatórios não informados');
        }

        try {
            const response = await api.post('/user/cadastrar', user);

            if (response.data.success) {
                localStorage.setItem("token", response.data.token);
                return 'ok';
            }

            return 'error';
        }
        catch (error) {
            if (error.response?.status === 401 && error.response.data.error === "email_registered") {
                return 'email_registered';
            }
            else {
                console.error("Erro ao registrar:", error);
                return 'error';
            }
        }
    },
};
