<template>
<div class="content mt-[29px]" v-if="loading">
    <div class="flex w-full justify-end  mb-[20px]">
        <button @click="abrirModalAlerta"
            class="flex items-center justify-center font-bold-segoe bg-[#ff2a2a] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[10px] mx-[20px]">
            <img src="@/assets/icons/utilities/warning.svg" class="w-6 h-6 mr-2" />
            apagar dados do usuário
        </button>
        <ModalAlert v-model="show" title="Alerta!" @confirm="apagarDadosUsuarioLogado" okButtonText="sim">
            <p>Todos os seus dados serão apagados permanentemente.</p>
            <p>Deseja continuar?</p>
        </ModalAlert>
    </div>
    <div id="header-perfil">
        <h1 class="font-bold-segoe text-center text-2xl text-[#F37335] leading-none mx-[58px] mb-3">
            Dados dos usuários ordenados por último acesso
        </h1>
    </div>
    <div class="mx-[28px]">
        <!-- <DataView :value="allUsers" paginator :rows="5">
            <template #list="slotProps"> -->
        <div class="flex flex-wrap">
            <div v-for="(item, index) in allUsers" :key="index" class="w-full p-3">
                <div class="flex flex-col xl:flex-row xl:items-start p-4 gap-4"
                    :class="{ 'border-t border-surface-200 dark:border-surface-700': index !== 0 }">
                    <div class="flex flex-col sm:flex-row justify-between items-start xl:items-start flex-1 gap-4">
                        <div class="flex flex-col items-start sm:items-start gap-3">
                            <div class="text-md text-surface-900"><strong class="me-2">Última
                                    atualização:</strong><br>{{
                                        item.ultimoLogin }}</div>
                            <div class="text-md text-surface-900"><strong class="me-2">Nome:</strong><br>{{ item.name }}
                            </div>
                            <div class="text-md text-surface-900"><strong class="me-2">Data nasc.:</strong><br>{{
                                item.birthday }}</div>
                            <div class="text-md text-surface-900"><strong class="me-2">Telefone:</strong><br>{{ item.cel
                                }}
                            </div>
                            <div class="text-md text-surface-900"><strong class="me-2">Email:</strong><br>{{ item.email
                                }}
                            </div>
                            <div class="text-md text-surface-900"><strong class="me-2">Genero:</strong><br>{{
                                item.gender }}
                            </div>
                            <div class="text-md text-surface-900"><strong class="me-2">Campanha:</strong><br>{{
                                item.trialCampaign }}</div>
                            <div class="text-md text-surface-900"><strong class="me-2">Criação do perfil:</strong><br>{{
                                item.createdAt }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- </template>
</DataView> -->

    </div>



    <!-- Formulário -->
    <!--
      <FormKit :disabled="!editar" type="text" name="name" id="name" label="Nome Completo" validation-visibility="live"
      validation-label="Nome Completo" :validation-messages="{ required: 'Nome completo é obrigatório' }"
        :classes="style_config" v-model="user.name" />

      <FormKit :disabled="!editar" type="date" name="birthday" id="birthday" label="Data de Nascimento"
        :validation="[['date_between', antes, hoje]]" :min="antes" :max="hoje" validation-visibility="live"
        validation-label="Data de Nascimento"
        :validation-messages="{ required: 'Data é obrigatório', date_between: `A data deve ser entre os anos ${antes.getUTCFullYear()} e ${hoje.getUTCFullYear()}.` }"
        :classes="style_config" v-model="nascimento" />

      <FormKit :disabled="!editar" type="select" name="gender" id="gender" label="Gênero" :classes="style_config"
        :options="[
          { label: 'Selecione um gênero', attrs: { disabled: true } },
          { label: 'Masculino', value: 'male' },
          { label: 'Feminino', value: 'female' },
          { label: 'Prefiro não informar', value: 'other' }
        ]" v-model="gender" />

      <FormKit :disabled="!editar" type="tel" name="whatsapp" id="whatsapp" label="Celular | Whatsapp"
        :classes="style_config" v-maska:[option]="maskedValue" placeholder="(DD) 99999-9999" v-model="whatsapp" />


      <FormKit v-if="!editar" type="button" label="Editar" name="editar" id="editar" :classes="{
        wrapper: 'flex flex-col',
        input: 'font-bold-segoe  bg-[#F37335] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px] my-8'
      }" @click="() => edit()" />
      <FormKit v-if="editar" type="submit" label="Salvar" name="submit" id="submit" :classes="{
        wrapper: 'flex flex-col',
        input: 'font-bold-segoe  bg-[#68A74E] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px] my-8',
      }" @click="salvar" />
-->
</div>
</template>

<script setup>
    import { ref, inject } from 'vue';
    import DataView from 'primevue/dataview';
    import { useRouter } from "vue-router";
    import ModalAlert from './Modals/ModalAlert.vue';
    import adminService from "@/services/admin.service.js";
    import userService from "@/services/user.service.js";
    import { useAuthStore } from "@/store";

    const auth = useAuthStore();
    const axios = inject('axios');
    const loading = ref(false);
    const allUsers = ref([])
    const router = useRouter();
    const show = ref(false);

    const reqUsers = async () => {
        try {
            const adminBaseUrl = `${process.env.VUE_APP_API_URL}/admin`;
            const response = await axios.get(`${adminBaseUrl}`);

            if (response.status === 403) {
                throw new Error('Acesso proibido. Autenticação necessária.');
            }

            if (response.data.success) {
                allUsers.value = response.data.users;
                loading.value = true;
            }
            else {
                router.push("/loading?to=/acao?m=1");
            }
        }
        catch (error) {
            console.error('Erro ao requisitar usuários: ', 'Usuário não autorizado');
            router.push("/loading?to=/acao?m=1");
        }
    }

    try {
        reqUsers();
    }
    catch (error) {
        router.push("/loading?to=/acao?m=1");
    }

    const abrirModalAlerta = async () => {
        show.value = true;        
    }

    const apagarDadosUsuarioLogado = async () => {
        show.value = false;
        
        const usuarioLogadoData = await userService.obterUsuarioLogado();

        adminService.apagarDadosUsuario(usuarioLogadoData.user._id);

        localStorage.clear();
        router.push("/");
    };

</script>
