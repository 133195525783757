import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const api = axios.create({
    baseURL: API_URL,
});

// const getAuthConfig = () => {
//     const token = localStorage.getItem("token");
//     return { headers: { Authorization: token } };
// };

export default {
    async get(identifier) {
        if (!identifier) {
            throw new Error('Identifier is required');
        }

        const url = `/plans/${identifier}`;

        try {
            const response = await api.get(url);
            return response.data;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    },
    async getAll() {
        const url = `/plans`;

        try {
            const response = await api.get(url);
            return response.data;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    },
}

