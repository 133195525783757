<template>
<div v-if="usuarioAssinante">
    <router-link to="/assinatura/detalhes"
        class="flex flex-col text-center font-bold-segoe bg-[#FEEEE2] border-none rounded-[8px] text-[#F37335] text-[14px] p-[10px] w-full">Você
        já um Hooy
        Ilimitado</router-link>
</div>
<div v-else-if="urlAssinatura">
    <a :href="urlAssinatura" class="flex flex-col font-bold-segoe text-center text-[14px] text-[#FFF] submit-btn"
        target="_blank">
        Assinar o Hooy Ilimitado
    </a>
</div>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import { useAuthStore } from "@/store";
    import userService from "@/services/user.service";
    import { useUserAuthStore } from "@/stores/userAuth.store.js";
    import planService from "@/services/plan.service.js";

    const authStore = useAuthStore();
    const usuarioAssinante = ref(false);
    const userAuthStore = useUserAuthStore();
    const urlAssinatura = ref('');

    const props = defineProps({
        plano: {
            type: String,
            required: true,
        },
        textoBotao: {
            type: String,
            default: 'Quero assinar o Hooy',
        },
    });

    const carregarLinkAssinatura = async (identificador) => {
        if (userAuthStore?.email && identificador) {
            try {
                const plano = await planService.get(identificador);

                if (plano?.data.url) {
                    urlAssinatura.value = `${plano.data.url}?prefilled_email=${encodeURIComponent(
                        userAuthStore.email
                    )}&locale=pt&client_reference_id=${userAuthStore.id}`;
                }
            }
            catch (error) {
                console.error('Erro ao carregar o link de assinatura:', error);
            }
        }
    };

    const carregarDadosUsuarioParaPagamento = async () => {
        usuarioAssinante.value = false;

        if (authStore.userId) {
            const result = await userService.findByUserId(authStore.userId);

            if (result) {
                if (userAuthStore && !userAuthStore.expired && result.paymentStatus && result.paymentStatus === "paid") {
                    usuarioAssinante.value = true;
                    return;
                }

                if (userAuthStore && userAuthStore.expired) {
                    usuarioAssinante.value = false;
                }

                if (result.paymentStatus && !result.paymentStatus !== "paid") {
                    usuarioAssinante.value = false;
                }

                await carregarLinkAssinatura(props.plano);
            }
        }
    };

    onMounted(async () => {
        await carregarDadosUsuarioParaPagamento();
    });
</script>

<style scoped>
    .submit-btn {
        background-color: #8bc756;
        padding: 10px;
        border: none;
        width: 100%;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
    }

    .submit-btn:hover {
        background-color: #8bc756;
    }
</style>
