<template><a v-if="gerenciarMinhaAssinatura" :href="gerenciarMinhaAssinatura" target="_blank"
    class="flex flex-col text-center font-bold-segoe bg-[#FEEEE2] border-none rounded-[8px] text-[#F37335] text-[14px] p-[10px] w-full">
    {{ textoBotao }}
</a></template>

<script setup>
    import { onMounted, ref } from 'vue';

    const gerenciarMinhaAssinatura = ref('');

    const props = defineProps({
        textoBotao: {
            type: String,
            default: 'Gerenciar minha assinatura',
        },
    });

    const carregarLinkGerenciamentoAssinatura = async () => {
        if (process.env.VUE_APP_STRIPE_BILLING_MANAGEMENT_URL) {
            gerenciarMinhaAssinatura.value = process.env.VUE_APP_STRIPE_BILLING_MANAGEMENT_URL;
        }
    };

    onMounted(() => {
        carregarLinkGerenciamentoAssinatura();
    });
</script>
