import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const api = axios.create({
    baseURL: API_URL,
});

const getAuthConfig = () => {
    const token = localStorage.getItem("token");
    return { headers: { Authorization: token } };
};

export default {
    async addEvent(userId, type, message, events) {
        if (!userId) {
            throw new Error('User is required');
        }

        if (!type) {
            throw new Error('Type is required');
        }

        const data = { type: type, message: message, events: events || {} };
        const url = `/user-logs/add-event/${userId}`;

        try {
            const response = await api.post(url, data, getAuthConfig());
            return response.data;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    },
}

