// stores/mensagemStore.js
import { defineStore } from 'pinia';

export const useUserAuthStore = defineStore('userAuthStore', {
    state: () => ({
        id: '',
        trial: '',
        trialDaysLeft: 0,
        paymentStatus: '',
        plan: '',
        email: '',
        expired: false,
    }),
    actions: {
        setInfo(id, trial, trialDaysLeft, paymentStatus, plan, email) {
            this.id = id;
            this.trial = trial;
            this.trialDaysLeft = trialDaysLeft;
            this.paymentStatus = paymentStatus;
            this.plan = plan;
            this.email = email;
            this.expired = trial && trialDaysLeft === 0;
        },
        clear() {
            this.id = '';
            this.trial = '';
            this.trialDaysLeft = 0;
            this.paymentStatus = '';
            this.plan = '';
            this.email = '';
            this.expired = false;
        },
    },
    getters: {
        getInfo: (state) => {
            return {
                id: state.id,
                trial: state.trial,
                trialDaysLeft: state.trialDaysLeft,
                paymentStatus: state.paymentStatus,
                plan: state.plan,
                email: state.email,
                expired: state.expired,
            };
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'userAuthStorage',
                storage: localStorage,
            },
        ],
    },
});
